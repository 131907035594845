<template>
  <div class="container">
    <div class="reboundary-card mt-5">
      <h1>
        User Profile
      </h1>
      <h2 v-if="user.is_admin">Admin User</h2>
        <strong>Organisation:</strong>
      <p> {{ currentUser.organisation }}</p>

      <b-form name="form" @submit.prevent="handleUpdate">
        <b-form-group
            label="Email Address"
            description="Your email address is also your login name."
        >
          <div class="underline-input">
            <b-input
                v-model="user.email"
                v-validate="'required|email|max:50'"
                type="email"
                class="form-control"
                name="email"
            />
          </div>
          <div v-if="submitted && errors.has('email')" class="alert-danger">
            {{ errors.first('email') }}
          </div>
        </b-form-group>

        <b-form-group
            label="Display Name"
            description="What you'd like us to call you"
        >
          <div class="underline-input">
            <input
                v-model="user.display_name"
                v-validate="'required|max:50'"
                class="form-control"
                name="display_name"
            />
          </div>
          <div
              v-if="submitted && errors.has('display_name')"
              class="alert-danger"
          >
            {{ errors.first('display_name') }}
          </div>
        </b-form-group>

        <b-form-group
            label="Change Password"
            description="At least 6 characters long. Recommendation: use a password manager to generate one for you."
        >
          <div class="underline-input">
          <input
              v-model="user.password"
              v-validate="'min:6|max:40'"
              ref="password"
              type="password"
              class="form-control"
              name="password"
          />
          </div>
          <div v-if="submitted && errors.has('password')" class="alert-danger">
            {{ errors.first('password') }}
          </div>
        </b-form-group>
        <b-form-group
            label="Verify Password"
            description="Verify your password to confirm."
        >
          <div class="underline-input">
          <input
              v-model="user.verify_password"
              v-validate="'confirmed:password'"
              type="password"
              class="form-control"
              name="verify_password"
          />
          </div>
          <div
              v-if="submitted && errors.has('verify_password')"
              class="alert-danger"
          >
            {{ errors.first('verify_password') }}
          </div>
        </b-form-group>

        <b-form-group
            label="Organisation"
            description="Only admins can change organisation."
            v-if="user.admin"
        >
          <input
              v-model="user.organisation"
              v-validate="'required|min:2|max:40'"
              type="text"
              class="form-control"
              name="organisation"
          />
          <div
              v-if="submitted && errors.has('organisation')"
              class="alert-danger"
          >
            {{ errors.first('organisation') }}
          </div>
          <b-form-group
              id="input-organisation_create_if_not_exists"
              v-if="user.admin"
          >
            <b-form-checkbox-group
                v-model="organisation_create_if_not_exists"
                id="checkboxes-4"
                :aria-describedby="ariaDescribedby"
            >
              <b-form-checkbox value="true"
              >Create this organisation if it doesn't exist</b-form-checkbox
              >
            </b-form-checkbox-group>
          </b-form-group>
        </b-form-group>

        <b-form-group>
          <b-button type="submit" class="btn btn-primary btn-block"
          >Update Details</b-button
          >
        </b-form-group>
      </b-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Profile',
  data() {
    return {
      submitted: false,
      successful: false,
      message: '',
      organisation_create_if_not_exists: false,
      // this.user is for the edited fields, not the currently logged in user
      user: {},
    };
  },
  computed: {
    currentUser() {
      // The currently logged-in user, not tied to the edit details form
      const user = this.$store.state.auth.user;
      console.log({ user });
      return user;
    },
  },
  created() {
    const user = this.$store.state.auth.user;
    this.updateUser(user)
  },
  mounted(){
    const user = this.$store.state.auth.user;
    console.log("Updated user", user)
    this.updateUser(user)
  },
  methods: {
    updateUser(user){
      console.log("Updating user from state:", user)
      if (user) {
        this.user.email = user.email;
        this.user.display_name = user.display_name;
        this.user.organisation = user.organisation;
        this.user.user_id = user.user_id;
      }
    },
    handleUpdate() {
      this.message = '';
      this.submitted = true;
      console.log('Validating');
      this.$validator.validate().then((isValid) => {
        console.log(isValid);
        if (isValid) {
          this.$store
            .dispatch('auth/edit', {
              user: this.user,
              organisation_create_if_not_exists:
                this.organisation_create_if_not_exists,
            })
            .then(
              (data) => {
                console.log('Returned: ', data)
                this.updateUser(data.user)
                this.message = data.message;
                this.successful = true;
              },
              (error) => {
                this.message =
                  (error.response && error.response.data) ||
                  error.message ||
                  error.toString();
                this.successful = false;
              }
            );
        }
      });
    },
  }
}
</script>
<style scoped>

.underline-input {
  margin-bottom: 0;
  margin-top: 0;
}

</style>
